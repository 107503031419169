<template>
  <div class="container">
    <div class="row row-cards">
      <div v-if="hasLeftSection" class="col-md-6">
        <article-forecast v-if="can(uiPermissions.FORECAST_ARTICLES_PORTIONING_EXPORT)"/>

        <div v-if="can([uiPermissions.ARTICLES_EXPORT, uiPermissions.INGREDIENT_EXPORT])" class="card">
          <div class="card-header">
            <h3 class="card-title">Recipes and Ingredients</h3>
          </div>
          <div class="card-body">
            <div class="row gap-8">
              <div v-if="can(uiPermissions.INGREDIENT_EXPORT)">
                <button

                  :disabled="submitting"
                  type="button"
                  class="btn btn-primary d-block"
                  @click="exportInventory">
                  Export inventory
                </button>
              </div>
              <div v-if="can(uiPermissions.ARTICLES_EXPORT)">
                <button
                  :disabled="submitting"
                  type="button"
                  class="btn btn-primary d-block"
                  @click="exportMasterArticles">
                  Export Master Articles
                </button>
              </div>
            </div>
          </div>
        </div>

        <form
          v-if="can(uiPermissions.RECIPES_CUSTOMER_FEEDBACK_EXPORT)"
          ref="recipeFeedback"
          class="card validation"
          novalidate
          @submit.prevent="exportRecipeFeedback">
          <div class="card-header">
            <h3 class="card-title">Recipe customer feedback</h3>
          </div>
          <div class="card-body">
            <div class="form-row">

              <div class="form-group col-md-6">
                <label class="form-label">Start date</label>
                <div class="input-group">
                  <input
                    v-model="recipeFeedbackStartDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(recipeFeedbackStartDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label">End date</label>
                <div class="input-group">
                  <input
                    v-model="recipeFeedbackEndDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(recipeFeedbackEndDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="submitting">
              Export
            </button>
          </div>
        </form>

        <form
          v-if="can(uiPermissions.PAYMENTS_RECONCILIATION_EXPORT)"
          ref="paymentReconciliation"
          class="card validation"
          novalidate
          @submit.prevent="exportPayments">
          <div class="card-header">
            <h3 class="card-title">Payment reconciliation</h3>
          </div>
          <div class="card-body">
            <div class="form-row">
              <file-input @fileChange="file => paymentReport = file" @input="() => paymentReport = null"/>
            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="submitting || !paymentReport">
              Export
            </button>
          </div>
        </form>

        <form
          v-if="can(uiPermissions.MENU_EXPORT)"
          ref="weeklyMenu"
          class="card validation"
          novalidate
          @submit.prevent="exportWeeklyMenu">
          <dimmer :active="submitting || downloading">
            <div class="card-header">
              <h3 class="card-title">Weekly Menu</h3>
            </div>
            <div class="card-body">

              <div class="form-group">
                <label class="form-lab-autoel">Choose week</label>
                <calendar-day-picker
                  v-model="weeklyMenu"
                  :disableThursday="true"
                  @input="fetchData"></calendar-day-picker>
                <p v-if="!weeklyMenu.item" class="small note text-danger error">No weekly menu found</p>
              </div>

              <div class="form-group">
                <label for="weekly-menu-type" class="form-lab-autoel">Type</label>
                <select
                  v-model="weeklyMenu.type"
                  class="form-control custom-select"
                  id="weekly-menu-type">
                  <option v-if="can(uiPermissions.MENU_EXPORT_ARTICLES)" value="articles">
                    Articles
                  </option>
                  <option v-if="can(uiPermissions.MENU_EXPORT_CLONE_ARTICLES)" value="clone-articles">
                    Clone articles
                  </option>
                  <option value="cards">Recipe cards</option>
                </select>
              </div>

            </div>
            <div class="card-footer text-right">
              <button
                v-if="weeklyMenu.type === 'cards'"
                type="submit"
                class="btn btn-primary"
                :disabled="submitting && weeklyMenu.id">
                Download
              </button>
              <button
                v-else
                type="submit"
                class="btn btn-primary"
                :disabled="submitting && weeklyMenu.id">
                Export
              </button>
            </div>
          </dimmer>
        </form>
      </div>

      <div :class="hasLeftSection?'col-md-6' : 'col-md-12'">
        <!-- TODO: change permisions if required to dessert export -->
        <market-orders v-if="can(uiPermissions.FORECAST_ARTICLES_PORTIONING_EXPORT)"/>
        <box-forecast v-if="can(uiPermissions.FORECAST_BOXES_EXPORT)"/>

        <div v-if="can(uiPermissions.CHURN_PREDICTION_EXPORT)" class="card">
          <div class="card-header">
            <h3 class="card-title">Churn Prediction</h3>
          </div>
          <div class="card-body">
            <button
              type="button"
              class="btn btn-primary d-block mt-2"
              :disabled="submitting"
              @click="exportChurnPrediction">
              Export Churn Prediction
            </button>
          </div>
        </div>

        <form
          v-if="can(uiPermissions.ORDERS_EXPORT)"
          ref="orders"
          class="card validation"
          novalidate
          @submit.prevent="exportOrders">
          <div class="card-header">
            <h3 class="card-title">Orders</h3>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col">
                <label class="form-label">Filter by</label>
                <div class="custom-control custom-radio">
                  <input
                    v-model="orders.deliveryDate"
                    type="radio"
                    class="custom-control-input"
                    :value="false"
                    id="ordersDeliveryDateFalse">
                  <label for="ordersDeliveryDateFalse" class="custom-control-label w-100">Created at</label>
                </div>
                <div class="custom-control custom-radio ml-2">
                  <input
                    v-model="orders.deliveryDate"
                    type="radio"
                    class="custom-control-input"
                    :value="true"
                    id="ordersDeliveryDateTrue">
                  <label for="ordersDeliveryDateTrue" class="custom-control-label w-100">Delivery date</label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label class="form-label">Status</label>
                <select v-model="orders.status" class="form-control custom-select">
                  <option value="">Processing & Completed</option>
                  <option value="created">Created</option>
                  <option value="canceled">Canceled</option>
                  <option value="failed">Failed</option>
                </select>
              </div>
              <div class="form-group col">
                <label class="form-label">Transporter</label>
                <select v-model="orders.transporter" class="form-control custom-select">
                  <option value="">All</option>
                  <option value="hellochef">Hellochef</option>
                  <option value="transcorp">Transcorp</option>
                </select>
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-6">
                <label class="form-label">Start date</label>
                <div class="input-group">
                  <input
                    v-model="ordersStartDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(ordersStartDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label">End date</label>
                <div class="input-group">
                  <input
                    v-model="ordersEndDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(ordersEndDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer d-flex justify-content-between align-items-center gap-12">
            <div>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="submitting">
                Export
              </button>
            </div>
          </div>
        </form>

        <form
          v-if="can(uiPermissions.ORDERS_FINANCE_EXPORT)"
          ref="financeOrders"
          class="card validation"
          novalidate
          @submit.prevent="exportFinanceOrders">
          <div class="card-header">
            <h3 class="card-title">Orders (Finance)</h3>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col">
                <label class="form-label">Filter by</label>
                <div class="custom-control custom-radio">
                  <input
                    v-model="financeOrders.deliveryDate"
                    type="radio"
                    class="custom-control-input"
                    :value="false"
                    id="financeOrdersDeliveryDateFalse">
                  <label for="financeOrdersDeliveryDateFalse" class="custom-control-label w-100">Created at</label>
                </div>
                <div class="custom-control custom-radio ml-2">
                  <input
                    v-model="financeOrders.deliveryDate"
                    type="radio"
                    class="custom-control-input"
                    :value="true"
                    id="financeOrdersDeliveryDateTrue">
                  <label for="financeOrdersDeliveryDateTrue" class="custom-control-label w-100">Delivery date</label>
                </div>
              </div>
              <div class="form-group col">
                <label class="form-label">Status</label>
                <select v-model="financeOrders.status" class="form-control custom-select">
                  <option value="">Processing & Completed</option>
                  <option value="created">Created</option>
                  <option value="canceled">Canceled</option>
                  <option value="failed">Failed</option>
                </select>
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-6">
                <label class="form-label">Start date</label>
                <div class="input-group">
                  <input
                    v-model="financeOrdersStartDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(financeOrdersStartDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label">End date</label>
                <div class="input-group">
                  <input
                    v-model="financeOrdersEndDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(financeOrdersEndDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="submitting">
              Export
            </button>
          </div>
        </form>

        <form
          v-if="can(uiPermissions.REPORTS_INGREDIENTS_EXPORT)"
          ref="inventory"
          class="card validation"
          novalidate
          @submit.prevent="exportStock">
          <div class="card-header">
            <h3 class="card-title">Inventory</h3>
          </div>
          <div class="card-body">
            <div class="form-row">

              <div class="form-group col-md-6">
                <label class="form-label">Start date</label>
                <div class="input-group">
                  <input
                    v-model="stockStartDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(stockStartDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label">End date</label>
                <div class="input-group">
                  <input
                    v-model="stockEndDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(stockEndDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>

              <div class="form-group col">
                <label class="form-lab-autoel">Type</label>
                <select v-model="stock.type" class="form-control custom-select">
                  <option value="articles">Articles</option>
                  <option value="packages">Package & others</option>
                </select>
              </div>

            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="submitting">
              Export stock on hand
            </button>
          </div>
        </form>

        <form
          v-if="can(uiPermissions.REPORTS_WASTAGE_EXPORT)"
          ref="wastage"
          class="card validation"
          novalidate
          @submit.prevent="exportWastage">
          <div class="card-header">
            <h3 class="card-title">Wastage</h3>
          </div>
          <div class="card-body">
            <div class="form-row">

              <div class="form-group col-md-6">
                <label class="form-label">Start date</label>
                <div class="input-group">
                  <input
                    v-model="wastageStartDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(wastageStartDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label">End date</label>
                <div class="input-group">
                  <input
                    v-model="wastageEndDate"
                    type="date"
                    class="form-control"
                    required>
                  <div class="input-group-append">
                    <span class="input-group-text">{{moment(wastageEndDate).format('ddd')}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="submitting">
              Export
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarDayPicker from '@/components/CalendarDayPicker';
import FileInput from '@/components/FileInput';
import ArticleForecast from '@/components/export/ArticleForecast';
import MarketOrders from '@/components/export/MarketOrders';
import BoxForecast from '@/components/export/BoxForecast';
import download from '@/mixins/download';
import {articles, churnPrediction, ingredients, inventory, orders, payments, recipes, weeklyMenus, weeklyMenusBasic} from '@/services';
import Permissions from '@hellochef/shared-js/enums/Permissions';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';

export default {
  components: {
    CalendarDayPicker,
    BoxForecast,
    ArticleForecast,
    MarketOrders,
    FileInput,
  },
  mixins: [
    download,
    submitting,
  ],
  data() {
    return {
      paymentReport: null,
      orderForecast: {
        startDate: thursdayBasedWeek(moment()),
      },
      orders: {
        deliveryDate: false,
        endDate: thursdayBasedWeek(moment()).add(6, 'days'),
        startDate: thursdayBasedWeek(moment()),
        status: '',
        transporter: '',
      },
      financeOrders: {
        deliveryDate: false,
        endDate: thursdayBasedWeek(moment()).add(6, 'days'),
        startDate: thursdayBasedWeek(moment()),
        status: '',
      },
      payments: {
        endDate: thursdayBasedWeek(moment()).add(6, 'days'),
        startDate: thursdayBasedWeek(moment()),
      },
      recipeFeedback: {
        endDate: thursdayBasedWeek(moment()).add(6, 'days'),
        startDate: thursdayBasedWeek(moment()),
      },
      stock: {
        endDate: moment().endOf('week'),
        startDate: moment().startOf('week'),
        type: 'articles',
      },
      wastage: {
        endDate: moment(),
        startDate: moment(),
      },
      weeklyMenu: {
        confirm: true,
        item: null,
        startDate: thursdayBasedWeek(moment()),
        type: 'articles',
      },
    };
  },
  computed: {
    orderForecastStartDate: {
      get() { return this.orderForecast.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.orderForecast.startDate = moment(newValue); },
    },
    ordersEndDate: {
      get() { return this.orders.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.orders.endDate = moment(newValue); },
    },
    ordersStartDate: {
      get() { return this.orders.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.orders.startDate = moment(newValue); },
    },
    financeOrdersEndDate: {
      get() { return this.financeOrders.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.financeOrders.endDate = moment(newValue); },
    },
    financeOrdersStartDate: {
      get() { return this.financeOrders.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.financeOrders.startDate = moment(newValue); },
    },
    paymentsEndDate: {
      get() { return this.payments.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.payments.endDate = moment(newValue); },
    },
    paymentsStartDate: {
      get() { return this.payments.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.payments.startDate = moment(newValue); },
    },
    recipeFeedbackEndDate: {
      get() { return this.recipeFeedback.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.recipeFeedback.endDate = moment(newValue); },
    },
    recipeFeedbackStartDate: {
      get() { return this.recipeFeedback.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.recipeFeedback.startDate = moment(newValue); },
    },
    stockEndDate: {
      get() { return this.stock.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.stock.endDate = moment(newValue); },
    },
    stockStartDate: {
      get() { return this.stock.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.stock.startDate = moment(newValue); },
    },
    wastageEndDate: {
      get() { return this.wastage.endDate.format('YYYY-MM-DD'); },
      set(newValue) { this.wastage.endDate = moment(newValue); },
    },
    wastageStartDate: {
      get() { return this.wastage.startDate.format('YYYY-MM-DD'); },
      set(newValue) { this.wastage.startDate = moment(newValue); },
    },
    hasLeftSection() {
      return this.can([
        Permissions.FORECAST_ARTICLES_PORTIONING_EXPORT,
        Permissions.ARTICLES_EXPORT,
        Permissions.INGREDIENT_EXPORT,
        Permissions.RECIPES_CUSTOMER_FEEDBACK_EXPORT,
        Permissions.PAYMENTS_RECONCILIATION_EXPORT,
        Permissions.MENU_EXPORT,
      ]);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    exportInventory() {
      this.submitIt(async () => {
        this.download(await ingredients.inventoryExport());
      });
    },
    exportMasterArticles() {
      this.submitIt(async () => {
        this.download(await articles.export());
      });
    },
    exportChurnPrediction() {
      this.submitIt(async () => {
        this.download(await churnPrediction.export());
      });
    },
    // Order forecast will be hidden to check who is using it as per the JIRA ticket below
    // https://hellochef.atlassian.net/browse/INTPRD-1267
    // exportOrderForecast() {
    //   this.submitValidatedForm(this.$refs.orderForecast, async () => {
    //     this.download(await inventory.exportForecastByDate(this.orderForecast));
    //   });
    // },
    exportOrders() {
      this.submitValidatedForm(this.$refs.orders, async () => {
        this.download(await orders.exportByDateRange(this.orders));
      });
    },
    exportFinanceOrders() {
      this.submitValidatedForm(this.$refs.financeOrders, async () => {
        this.download(await orders.exportFinanceOrdersByDateRange(this.financeOrders));
      });
    },
    async exportPayments() {
      /* this.submitValidatedForm(this.$refs.paymentReconciliation, async () => {
        this.download(await payments.exportPaymentReconciliation(this.payments));
      }); */
      try {
        await payments.generateReconciliationReport(this.paymentReport);
        this.$toasted.success('You will receive an email when the report is done');
      }
      catch (e) {
        this.$toasted.error('An error has occurred');
      }
    },
    exportRecipeFeedback() {
      this.submitValidatedForm(this.$refs.recipeFeedback, async () => {
        this.download(await recipes.exportFeedbackByDateRange(this.recipeFeedback));
      });
    },
    exportStock() {
      this.submitValidatedForm(this.$refs.inventory, async () => {
        this.download(await inventory.exportIngredientsByDateRange(this.stock, {'allowed_in_recipes': this.stock.type === 'articles' ? 1 : 0}));
      });
    },
    exportWastage() {
      this.submitValidatedForm(this.$refs.wastage, async () => {
        this.download(await inventory.exportWastageByDateRange(this.wastage));
      });
    },
    exportWeeklyMenu() {
      this.submitValidatedForm(this.$refs.weeklyMenu, async () => {
        if (this.weeklyMenu.type === 'articles') { return this.download(await weeklyMenus.exportIngredientsById(this.weeklyMenu.item.id)); }

        if (this.weeklyMenu.type === 'clone-articles') { return this.download(await weeklyMenus.exportCloneIngredientsById(this.weeklyMenu.item.id)); }

        if (this.weeklyMenu.type === 'cards') { return this.downloadRecipeCards(this.weeklyMenu.item); }
      });
    },
    async fetchData() {
      this.weeklyMenu.item = null;
      this.submitting = true;
      this.error = false;

      try {
        const result = await weeklyMenusBasic.getByParameters({
          limit: 1,
          query: moment(this.weeklyMenu.startDate).format('YYYY-MM-DD'),
          status: ['scheduled', 'published', 'draft'],
        });
        this.weeklyMenu.item = result.items[0];
      }
      catch (ex) {
        this.error = true;
        console.error(ex);
      }
      finally {
        this.submitting = false;
      }
    },
  },
};
</script>
