<template>
  <div class="w-100">
    <select
      v-model="selectedWeeklyMenu"
      class="form-control custom-select"
      :class="{'is-invalid': hasError }"
      @change="onchange"
    >
      >
      <option :value="null">Select Weekly Menu</option>
      <option
        v-for="week in weeklyMenus"
        :key="week.id"
        :value="week.id">
        {{weekName(week.startDate, {weekFrom: false})}}
      </option>
    </select>
    <div v-if="hasError" class="invalid-feedback d-block">{{requiredErrorMessage}}</div>
  </div>
</template>

<script>

import {weeklyMenusBasic} from '@/services';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import moment from 'moment-timezone';

export default {
  props: {
    required: {
      default: false,
      type: Boolean,
    },
    requiredErrorMessage: {
      default: 'Weekly Menu Field is Required',
      type: String,
    },
  },
  data() {
    return {
      weeklyMenus: [],
      selectedWeeklyMenu: null,
      hasError: false,
    };
  },
  created() {
    weeklyMenusBasic.getByParameters({
      column: 'id',
      direction: 'asc',
      limit: 25,
      startDateFrom: thursdayBasedWeek(moment()).format('YYYY-MM-DD'),
      status: ['published', 'scheduled'],
    }).then(result => {
      this.weeklyMenus = result.items;

      const startWeek = this.getPurchaseStartWeek();
      // set default earliest week available
      this.selectedWeeklyMenu = this.weeklyMenus[this.weeklyMenus.length - startWeek] ? this.weeklyMenus[this.weeklyMenus.length - startWeek].id : null;
      this.$emit('on-change', this.selectedWeeklyMenu);
    });
  },
  methods: {
    onchange() {
      this.hasError = false;
      if (!this.selectedWeeklyMenu && this.required) {
        this.hasError = true;
      }

      this.$emit('on-change', this.selectedWeeklyMenu);
    },
    getPurchaseStartWeek() {
      // by default they need the current week for thursday = 4 and friday = 5 only
      const daysForCurrentWeek = ['4', '5'];

      // if it's not thursday or friday we should grab next week as default
      return daysForCurrentWeek.includes(moment().format('d')) ? 1 : 2;
    },
  },
};

</script>
