<template>
  <form
    ref="dessertOrders"
    data-test="card-dessert-orders"
    class="card validation"
    @submit.prevent="exportMarketOrdersByType">

    <div class="card-header">
      <h3 class="card-title">Market Orders</h3>
    </div>

    <div class="card-body">
      <div class="form-group">
        <label class="form-label">Choose Export Type</label>
        <select
          v-model="exportType"
          class="form-control custom-select"
          data-test="market-order-export-type"
          required>
          <option :value="null" disabled>Select market export type</option>
          <option
            v-for="data in Object.values(MARKET_ORDER_EXPORT_TYPES)"
            :key="data.value"
            :value="data.value">
            {{data.label}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label">Choose Delivery Date</label>
        <calendar-day-picker
          v-model="date"></calendar-day-picker>
        <div v-if="errorMessage" class="invalid-feedback d-block">{{errorMessage}}</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <small>Note: Market Order Renewals happen approximately 8 PM onwards</small>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="submit"
        data-test="btn-export-dessert-article-portioning"
        class="btn btn-primary"
      >
        Export
      </button>
    </div>
  </form>
</template>

<script>

import CalendarDayPicker from '@/components/CalendarDayPicker';
import download from '@/mixins/download';
import {market} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';

const MARKET_ORDER_EXPORT_TYPES = {
  'articles': {
    label: 'Market Recipe Articles Portioning',
    value: 'articles',
    endpointFn: 'exportByParameters',
  },
  'orders': {
    label: 'Market Orders',
    value: 'orders',
    endpointFn: 'exportOrdersByParameters',
  },
};

export default {
  components: {
    CalendarDayPicker,
  },
  mixins: [
    download,
    submitting,
  ],
  data() {
    return {
      MARKET_ORDER_EXPORT_TYPES,
      exportType: null,
      date: {
        startDate: moment(),
      },
      errorMessage: '',
    };
  },
  methods: {
    exportMarketOrdersByType() {
      const selectedExportType = this.MARKET_ORDER_EXPORT_TYPES[this.exportType];
      this.submitIt(async () => {
        const payload = {
          delivery_date: this.date.startDate.format('YYYY-MM-DD'),
        };

        try {
          return this.download(await market?.[selectedExportType.endpointFn]?.(payload));
        }
        catch (e) {
          this.errorMessage = 'There is no data to download';
        }
      });
    },
  },
};
</script>
